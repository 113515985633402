import {
  useState,
  useEffect
} from 'react'

import {
  makeStyles
} from '@material-ui/styles'

const useClasses = makeStyles({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'bisque'
  },
  registries: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  please: {
    fontFamily: 'Merriweather, serif'
  },
  registry: {
    width: 200,
    marginTop: 48
  },
  logo: {
    width: '100%',
    padding: 16,
    boxSizing: 'border-box',
    border: '3px solid white',
    '&:hover': {
      backgroundColor: 'white'
    }
  }
})

type Registry = {
  image: string
  url: string
}

type Registries = {
  registries: Registry[]
}

const App = () => {
  const [registries, setRegistries] = useState<Registry[]>([])

  useEffect(() => {
    const get = async () => {
      try {
        const reply = await fetch('registries.json')

        const data: Registries = await reply.json()

        setRegistries(data.registries)
      } catch (error) {
        console.log(error)
      }
    }

    get()
  }, [])

  const classes = useClasses()

  return (
    <div className={classes.container}>
      <div className={classes.registries}>
        <div className={classes.please}>
          Please select a Registry:
        </div>
        {registries.map((registry, index) => (
          <div
            key={`registry-${index}`}
            className={classes.registry}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href={registry.url}
            >
              <img
                className={classes.logo}
                alt={registry.image}
                src={`${registry.image}.png`}
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export default App
